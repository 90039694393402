.Hangman {
  color: black;
  text-align: center;
}

.Hangman img {
  border: 10px solid white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.27),
    0 0 40px rgba(0, 0, 0, 0.06) inset;
  border-radius: 20px;
}

.Hangman h1 {
  font-size: 4rem;
  font-weight: 100;
  margin-bottom: 0.1em;
  margin-top: 0;
}

.Hangman-word {
  letter-spacing: 1em;
  margin: 0.4em -1em 0.2em 0;
  font-size: 2rem;
}

.Hangman-btns {
  text-align: center;
  display: inline-block;
  width: 250px;
  margin-top: 0;
}

.Hangman .word-btn {
  border: none;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  width: 30px;
  margin: 5px 2px 2px 2px;
  letter-spacing: 1px;
  outline: none;
  background: #ffc107;
  color: #fff;
  box-shadow: 0 6px #ff9800;
  border-radius: 5px;
  padding-top: 4px;
}

.reset-btn {
  width: 60px;
  border: none;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  letter-spacing: 1px;
  outline: none;
  background: green;
  color: #fff;
  box-shadow: 0 6px greenyellow;
  border-radius: 5px;
  padding-top: 4px;
}

.Hangman .word-btn:hover {
  background-color: #ff9800;
}

.Hangman .reset-btn:hover {
  background-color: #003c12;
}

.Hangman .word-btn:disabled {
  background: #bdbdbd;
  color: #eeeeee;
  box-shadow: 0 6px #9e9e9e;
}

.Hangman .reset-btn:disabled {
  background: #bdbdbd;
  color: #eeeeee;
  box-shadow: 0 6px #9e9e9e;
}
