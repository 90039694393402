@import url("https://use.typekit.net/ofb0enx.css");

body {
  font-family: 'Sofia Pro', sans-serif;
}

.App {
  text-align: center;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
}

.row {
  width: 100%;
  }
  .column {
    width:100%;
    justify-content: center;
    align-content: center;
  }
  @media (min-width: 48em) {
    .column {
      width: 50%;
      float:left;
    }
    .row {
      content: "";
      display: table;
      clear: both;
    }
  }